

import { computed, ref , reactive} from 'vue';
import {EmailDetail} from "@/models/emailDetail";
import {VideoDetail} from "@/models/videoDetail";
import EvaluationRepository from "@/services/EvaluationRepository";

export default {

  setup(){
    const emailDetail = ref({} as EmailDetail);
    const link = ref("video/default.mp4");

    const valid = () => {
      return emailDetail.value.name && emailDetail.value.email;
    };
    const listVideo = ref([] as VideoDetail[]);

      EvaluationRepository.getListVideoDetail()
          .then((response) => {
              listVideo.value = response.data;
              console.log("###################@@@@@@@@@@@@@@@@@ EvaluationRepository: " + JSON.stringify(listVideo.value));
          })
          .catch(err => {
          });

      const setVideoDefault = () => {
          link.value = "video/default.mp4";
      }
      const temp = ref();
      const PlayVideo = (id: number) => {
          clearTimeout(temp.value);
           listVideo.value.filter(x => {if(x.id == id) link.value = x.link});
            console.log("$################################# PlayVideo list: " + listVideo.value.filter(x => x.id == id));
            console.log("$################################# PlayVideo id: " + id);
            console.log("$################################# PlayVideo link: " + link.value);
          temp.value = setTimeout(setVideoDefault, 60 * 1000);
      }

    return {
      emailDetail,
      valid,
        listVideo,
      link,
        PlayVideo,
    }
  }
}

