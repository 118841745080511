import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from "@/pages/Home.vue";
import EvaluationOfPatient from "@/pages/EvaluationOfPatient.vue";
import VideoPage from "@/pages/VideoPage.vue";
import InfoPatient from "@/pages/InfoPatient.vue";
import WebView from "@/pages/WebView.vue";
import evaluationOfPatient from "@/pages/EvaluationOfPatient.vue";
import mainMenu from "@/pages/MainMenu.vue";
import viewPrice from "@/pages/ViewPrice.vue";
import QrCodeRender from "@/pages/QrCodeRender.vue";
import QrCode from "@/pages/QrCode.vue";
import medicalExaminationProcess from "@/pages/MedicalExaminationProcess.vue";
import survey from "@/pages/Survey.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'home',
    component: Home,
    props: true
  },
  {
    path: '/evalofpatient',
    name: 'evalofpatient',
    component: EvaluationOfPatient,
  },
  {
    path: '/videopage',
    name: 'videopage',
    component: VideoPage,
  },
  {
    path: '/infopatient',
    name: 'infopatient',
    component: InfoPatient,
  },
  {
    path: '/webview',
    name: 'webview',
    component: WebView,
  },
  {
    path: '/mainMenu',
    name: 'mainMenu',
    component: mainMenu,
  },
  {
    path: '/viewPrice',
    name: 'viewPrice',
    component: viewPrice,
  },
  {
    path: '/medicalExaminationProcess',
    name: 'medicalExaminationProcess',
    component: medicalExaminationProcess,
  },
  {
    path: '/survey',
    name: 'survey',
    component: survey,
  },
  {
    path: '/QrCodeRender/:id',
    name: 'QrCodeRender',
    component: QrCodeRender,
    props: true
  },
  {
    path: '/QrCode/:id',
    name: 'QrCode',
    component: QrCode,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
