import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueQrcode = _resolveComponent("VueQrcode")!

  return (_openBlock(), _createBlock(_component_VueQrcode, {
    value: $setup.st,
    width: 200,
    level: "H",
    type: "image/png",
    class: "centerimage"
  }, null, 8, ["value"]))
}