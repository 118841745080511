<template>
  <div class="app-container">
    <Toast />
    <div class="card">
<!--    <TabMenu :model="homeMenu" />-->
<!--    <div class="app-content">-->
      <router-view/>
    </div>
<!--    </div>-->
  </div>
</template>

<style lang="scss">
.app-container {
  margin: 0 auto;
  max-width: 1900px;
  width: 100%;
  height: inherit;
  background: #F7F8F9;
  .app-content {
    margin: 8px;
  }
}
::v-deep(.tabmenudemo-content) {
  padding: 2rem 1rem;
}
</style>
<script>
import TopNavBar from "@/components/TopNavBar";
import {useStore} from "vuex";
import {computed, ref} from "vue";

export default {
  setup() {
    const store = useStore();
    const homeMenu = computed(() => {
      const menu = ref([
        {
          label:'Khảo sát đánh giá của NB',
          icon:'pi pi-fw pi-star-fill',
          to: '/evalofpatient'
        },
        {
          label:'Hướng dẫn đường đi cho NB',
          icon:'pi pi-fw pi-video',
          to: '/videopage'
        },
        {
          label:'Tra cứu thông tin khám bệnh của NB',
          icon:'pi pi-fw pi-search',
          to: '/infopatient'
        },
        {
          label:'Website bệnh viện',
          icon:'pi pi-fw pi-globe',
          url: '/WebView'
        },
      ]);
      return menu.value;
    })

    return {
      homeMenu
    }
  }
}
</script>
