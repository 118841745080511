

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import {PatientInfo} from "@/models/patientInfo";
import {Time} from "@/models/time";

export default {

  setup(){
    const router = useRouter();
    const register = ref({} as Register);
    const toast = useToast();
    const store = useStore();
    const patientInfos = ref([] as PatientInfo[]);
    const patient = ref({} as PatientInfo);
    const mayte  = ref("");
    const thoigian = ref([] as Time[]);
    const ngaykham = ref("");
    const loadingBar = ref(false);




    const searchInfo = () => {
      loadingBar.value = true;
      const temp = ref({} as PatientInfo);
      patient.value = temp.value;
      AuthRepository.patientInfo(mayte.value)
              .then((response) => {
                patientInfos.value = response.data;
                patientInfos.value.forEach(x => thoigian.value.push({ngaykham : x.ngay_kham}));
                //patient.value = patientInfos.value[0];
                console.log("############$$$$$$$$$$$$$$$$$$$$$$ searchInfo: " + JSON.stringify(patientInfos.value));
                console.log("############$$$$$$$$$$$$$$$$$$$$$$ thoigian: " + JSON.stringify(thoigian.value));
                loadingBar.value = false;
                if(patientInfos.value.length < 1)
                {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail: "Không tìm thấy thông tin người bệnh, vui lòng nhập lại mã người bệnh",
                    life: 2000
                  });
                }
                else
                {
                  toast.add({
                    severity: 'success',
                    summary: 'Tìm kiếm thông tin thành công',
                    detail: "Vui lòng lựa chọn thời gian để tra cứu",
                    life: 4000
                  });
                }
              })
              .catch(err => {
                loadingBar.value = false;
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail: "Không tìm thấy thông tin người bệnh, vui lòng nhập lại mã người bệnh",
                  life: 2000
                });
              });
    };

    const clear = () => {
      const temp = ref({} as PatientInfo);
      patientInfos.value = [];
      patient.value = temp.value;
      mayte.value = "";
      thoigian.value = [];
      ngaykham.value = "";
    }
    const temp = ref();
    const selectDate = () => {
      clearTimeout(temp.value);
      patientInfos.value.forEach(x => {if(x.ngay_kham == ngaykham.value) patient.value = x});
      temp.value = setTimeout(clear, 20 * 1000);
    }

    return {
      register,
      searchInfo,
      mayte,
      patientInfos,
      patient,
      thoigian,
      ngaykham,
      selectDate,
      loadingBar,
    }
  }
}

