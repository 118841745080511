

import { computed, ref , reactive} from 'vue';
import {EmailDetail} from "@/models/emailDetail";
import {VideoDetail} from "@/models/videoDetail";
import EvaluationRepository from "@/services/EvaluationRepository";
import { useRouter } from 'vue-router';

export default {

  setup(){
    const emailDetail = ref({} as EmailDetail);
    const link = ref("video/default.mp4");
    const list = ref([
      {label:'XIN NHẤN TRỰC TIẾP VÀO MÀN HÌNH CẢM ỨNG ĐỂ SỬ DỤNG\n' +
            'NẾU CÓ RẮC RỐI XIN LIÊN HỆ SĐT : 0905-658-333\n', value: "infopatient" , color: "#89af48"},
      {label:'TRA CỨU THÔNG TIN KHÁM BỆNH CỦA NGƯỜI BỆNH', value: "infopatient" , color: "#89af48"},
      {label:'HƯỚNG DẪN ĐƯỜNG ĐI CHO NGƯỜI BỆNH', value: "videopage" , color: "#89af48"},
      {label:'WEBSITE BỆNH VIỆN – GIỚI THIỆU BỆNH VIỆN', value: "webview" , color: "#89af48"},
      {label:'KHẢO SÁT ĐÁNH GIÁ HÀI LÒNG CỦA NGƯỜI BỆNH', value: "evalofpatient" , color: "#89af48"},
      {label:'BẢNG GIÁ DANH MỤC DỊCH VỤ KHÁM CHỮA BỆNH TẠI BỆNH VIỆN', value: "viewPrice" , color: "#89af48"},
      {label:'QUY TRÌNH KHÁM CHỮA BỆNH TẠI BỆNH VIỆN', value: "medicalExaminationProcess" , color: "#89af48"},
      {label:'KHẢO SÁT CƠ SỞ Y TẾ XANH - SẠCH - ĐẸP', value: "survey" , color: "#89af48"},
    ]);
    const router = useRouter();

    const valid = () => {
      return emailDetail.value.name && emailDetail.value.email;
    };
    const listVideo = ref([] as VideoDetail[]);

      EvaluationRepository.getListVideoDetail()
          .then((response) => {
              listVideo.value = response.data;
              console.log("###################@@@@@@@@@@@@@@@@@ EvaluationRepository: " + JSON.stringify(listVideo.value));
          })
          .catch(err => {
          });

      const setVideoDefault = () => {
          link.value = "video/default.mp4";
      }
      const temp = ref();
      const PlayVideo = (id: number) => {
          clearTimeout(temp.value);
           listVideo.value.filter(x => {if(x.id == id) link.value = x.link});
            console.log("$################################# PlayVideo list: " + listVideo.value.filter(x => x.id == id));
            console.log("$################################# PlayVideo id: " + id);
            console.log("$################################# PlayVideo link: " + link.value);
          temp.value = setTimeout(setVideoDefault, 60 * 1000);
      }

      const clickButton = (st: string) => {
        router.push({
          name: st,
        });
      }

    return {
      emailDetail,
      valid,
        listVideo,
      link,
        PlayVideo,
      list,
      clickButton,
      products: null,
      layout: 'grid',
      sortKey: null,
      sortOrder: null,
      sortField: null,
      sortOptions: [
        {label: 'Price High to Low', value: '!price'},
        {label: 'Price Low to High', value: 'price'},
      ]
    }
  }
}

