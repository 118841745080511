import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("iframe", {
    src: `http://benhvienyhoccotruyendanang.vn/`,
    width: "100%",
    height: "1000px",
    frameborder: "0"
  }, "\n  ", 8, _hoisted_1))
}