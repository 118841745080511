
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router'
import QrcodeVue from "qrcode.vue";
import VueQrcode from 'vue-qrcode';

export default {
  components: {
    QrcodeVue,
    VueQrcode,
  },
  props: {
    id: String,
  },
  setup(props) {
    const route = useRoute();

    const date = ref(route.query.date as string);
    const st = ref("");
    st.value = props.id;

    return {
      st,
      date,
      size: 150,
    }
  }
}

