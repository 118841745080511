
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router'
import QrcodeVue from "qrcode.vue";
import axios from "axios";

export default {
  components: {
    QrcodeVue,
  },
  props: {
    id: String,
  },
  setup(props) {
    const route = useRoute();

    const date = ref(route.query.date as string);
    const st = ref("");
    st.value = "http://localhost:8080/" + "qrCodeRender/" + props.id;

    axios
        .get(`${st.value}`, {
          responseType: 'blob'
        })
        .then(function (response) {
          console.log(response.data);
        });


    return {
      st,
      date,
      size: 100,
    }
  }
}


function authHeader(userType: any): HeadersInit | undefined {
    throw new Error('Function not implemented.');
}
