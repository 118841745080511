

import { useStore } from 'vuex'
import { computed } from 'vue'
import EvaluationOfPatient from "@/pages/EvaluationOfPatient.vue";
import MainMenu from "@/pages/MainMenu.vue";

export default {
  components: {
    // LoginPage,
    EvaluationOfPatient,
    MainMenu,
  },
  setup(){
    const store = useStore();
    const isLoggedIn = computed(() => !!store.state.token);

    return {
      isLoggedIn
    }
  }
}
